<script setup lang="ts">
import {
  useForwardPropsEmits,
  type DialogRootEmits,
  type DialogRootProps,
} from "radix-vue";

const props = defineProps<
  DialogRootProps & {
    title?: string;
    description?: string;
  }
>();
const emits = defineEmits<DialogRootEmits>();

const delegatedProps = computed(() => {
  const { title, description, ...delegated } = props;
  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const { isDesktop } = useDevice();
const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});
</script>

<template>
  <template v-if="isMounted">
    <UIDialogRoot v-if="isDesktop" v-bind="forwarded">
      <UIDialogTrigger as-child>
        <slot name="trigger" />
      </UIDialogTrigger>

      <UIDialogContent>
        <UIDialogHeader>
          <UIDialogTitle v-if="props.title">
            {{ props.title }}
          </UIDialogTitle>

          <UIDialogDescription v-if="props.description">
            {{ props.description }}
          </UIDialogDescription>
        </UIDialogHeader>

        <slot name="content" />

        <UIDialogFooter>
          <UIDialogClose as-child>
            <slot name="cancel">
              <UIButton :label="$t('cancel')" variant="outlined" />
            </slot>
          </UIDialogClose>

          <UIDialogClose as-child>
            <slot name="action" />
          </UIDialogClose>

          <slot name="submit" />
        </UIDialogFooter>
      </UIDialogContent>
    </UIDialogRoot>

    <UIDrawer v-else v-bind="{ ...forwarded, title, description }">
      <template #trigger>
        <slot name="trigger" />
      </template>

      <template #content>
        <slot name="content" />
      </template>

      <template v-if="$slots.action" #action>
        <slot name="action" />
      </template>

      <template #submit>
        <slot name="submit" />
      </template>

      <template #cancel>
        <slot name="cancel" />
      </template>
    </UIDrawer>
  </template>

  <!-- Placeholder for SSR -->
  <template v-else>
    <div style="display: none">
      <slot name="trigger" />
    </div>
  </template>
</template>
