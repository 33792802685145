<script setup lang="ts">
import { useForwardPropsEmits } from "radix-vue";
import type { DrawerRootEmits, DrawerRootProps } from "vaul-vue";

const props = withDefaults(
  defineProps<
    DrawerRootProps & {
      title?: string;
      description?: string;
    }
  >(),
  {
    shouldScaleBackground: true,
    title: undefined,
    description: undefined,
  },
);

const emits = defineEmits<DrawerRootEmits>();

const delegatedProps = computed(() => {
  const { title, description, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <UIDrawerRoot v-bind="forwarded">
    <UIDrawerTrigger as-child>
      <slot name="trigger" />
    </UIDrawerTrigger>

    <UIDrawerContent>
      <UIDrawerHeader class="text-left">
        <UIDrawerTitle>
          {{ props.title }}
        </UIDrawerTitle>

        <UIDrawerDescription>
          {{ props.description }}
        </UIDrawerDescription>
      </UIDrawerHeader>

      <div class="px-4">
        <slot name="content" />
      </div>

      <UIDrawerFooter>
        <UIDrawerClose v-if="$slots.action" as-child>
          <slot name="action" />
        </UIDrawerClose>

        <slot name="submit" />

        <UIDrawerClose as-child>
          <slot name="cancel">
            <UIButton :label="$t('cancel')" variant="outlined" />
          </slot>
        </UIDrawerClose>
      </UIDrawerFooter>
    </UIDrawerContent>
  </UIDrawerRoot>
</template>
